import React, { useEffect, useState, useMemo } from 'react';
import { useClassNames } from '@vodafoneis/sjonvarpskjarni-js-lib';
import styled from 'styled-components';
import { processCmsImageUrl } from '../utils/urls';

const IMAGES_HOST = process.env.NEXT_PUBLIC_IMAGES_HOST;

export const processImageUrl = (url) => {
	if (!url.match(/^\/_next/)) {
		return `${IMAGES_HOST}${url}`;
	}

	return url;
};

export const ImageComponentContainer = styled.div`
	background: rgba(255, 255, 255, 0.1);
`;

type ImageComponentProps = {
	images: string[];
	isStrapiImage?: boolean;
	className?: string;
	onLoaded?: () => void;
	onError?: () => void;
};

export const ImageComponent: React.FC<ImageComponentProps> = (props) => {
	const { images: urls, isStrapiImage, className, onLoaded, onError } = props;

	const [loaded, setLoaded] = useState(false);
	const [error, setError] = useState(false);
	const [imageIndex, setImageIndex] = useState(0);
	const [imageUrl, setImageUrl] = useState(null);
	const images = useMemo(() => urls?.filter((url) => url) ?? [], [urls]);

	const imageClassNames = useClassNames(className, { loaded, error, 'image-component': true });

	useEffect(() => {
		const image = new Image();
		const currentImageUrl = images[imageIndex] ? (isStrapiImage ? processCmsImageUrl(images[imageIndex]) : processImageUrl(images[imageIndex])) : null;

		const onImageLoad = () => {
			setLoaded(true);
			setError(false);
			setImageUrl(currentImageUrl);

			if (onLoaded) onLoaded();
		};

		const onImageError = () => {
			setLoaded(false);
			setError(true);
			setImageIndex(imageIndex + 1);
			setImageUrl(null);

			if (onError) onError();
		};

		image.addEventListener('load', onImageLoad);
		image.addEventListener('error', onImageError);

		if (currentImageUrl) {
			image.src = currentImageUrl;
		}

		return () => {
			image.removeEventListener('load', onImageLoad);
			image.removeEventListener('error', onImageError);
		};
	}, [imageIndex, images, isStrapiImage, onError, onLoaded]);

	const backgroundImage = useMemo(() => (imageUrl ? `url(${imageUrl})` : null), [imageUrl]);

	return <div className={imageClassNames} style={{ backgroundImage }} />;
};
