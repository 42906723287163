import Media from '../models/strapi/Media/Media';
import Multimedia from '../models/strapi/Multimedia';

export const getAdUrl = (ad) => {
	const { type, category, content, product, url } = ad;

	if (['movie', 'media'].includes(type)) {
		return content?.id ? `/movie/${content.id}` : null;
	}

	if (['series', 'list'].includes(type)) {
		return content?.id ? `/series/${content.id}` : null;
	}

	if (['grid', 'list', 'category'].includes(type)) {
		let categoryUrl = null;

		if (category?.id) {
			categoryUrl = `/category/${category.id}`;
		}

		if (categoryUrl !== null) {
			if (type === 'grid') {
				categoryUrl += '/grid';
			} else {
				categoryUrl += '/list';
			}
		}

		return categoryUrl;
	}

	if (type === 'product') {
		return product?.id ? `/product/${product.id}` : null;
	}

	if (type === 'tv') {
		return '/live';
	}

	if (type === 'purchases') {
		return '/rentals';
	}

	if (type === 'catchup') {
		return '/catchup';
	}

	if (type === 'url') {
		return url;
	}

	if (type === 'sports-world') {
		return '/bestadeildin';
	}

	return null;
};
const IMAGES_HOST = process.env.NEXT_PUBLIC_IMAGES_HOST;

export const processImageUrl = (url) => {
	if (!url.match(/^\/_next/)) {
		return `${IMAGES_HOST}${url}`;
	}
	return url;
};
export const processCmsImageUrl = (url) => {
	if (!url?.match(/^\/_next/)) {
		// we might add some varnish cache behind this later
		return `${url}`;
	}

	return url;
};

export const processCmsMediaUrl = (isMobile : boolean, image: Media, multimedia: Multimedia) => {
	if (!multimedia || !multimedia?.image) {
		return processCmsImageUrl(image?.data?.attributes?.url);
	} if (!isMobile || !multimedia?.mobileImage) {
		return processCmsImageUrl(multimedia?.image?.data?.attributes?.url);
	}
	return processCmsImageUrl(multimedia?.mobileImage?.data?.attributes?.url);
};
export const processCmsMediaAltText = (isMobile : boolean, image: Media, multimedia: Multimedia) => {
	if (!multimedia || !multimedia?.image) {
		return image?.data?.attributes?.alternativeText;
	} if (!isMobile || !multimedia?.mobileImage) {
		return multimedia?.image?.data?.attributes?.alternativeText;
	}
	return multimedia?.mobileImage?.data?.attributes?.alternativeText;
};
