import styled from 'styled-components';
import { PRIMARY } from '../styles/colors';

export const Highlight = styled.div`
	display: none;
	@media (hover) {
		opacity: 0;
		display: block;
		position: absolute;
		top: -6px;
		left: -6px;
		right: -6px;
		bottom: -6px;
		border: 4px solid ${PRIMARY};
		transition: opacity 0.2s ease-in-out;
		border-radius: 12px;

		&:hover {
			opacity: 1;
		}
	}
`;
