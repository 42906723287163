import { useMemo, useCallback } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { Series, Episode, ProductType, ModuleType, ModuleTemplate, ImagePreset, Product, Playable } from '@vodafoneis/sjonvarpskjarni-js-lib';
import moment from 'moment';
import playIconImage from '../../public/images/play.png';
import { getAdUrl, processCmsImageUrl } from '../utils/urls';
import { MediaComponentType } from '../models/MediaComponentType';
import { MediaComponentProps } from '../models/MediaComponentProps';
import MediaPoster from '../models/strapi/MediaPoster';

const { HOPSTER } = ModuleType;
const { OVERLAY_ICON, OVERLAY, PROMO } = ModuleTemplate;
const { AD, CATCHUP, CONTENT, EVENT, HISTORY, PRODUCT, PURCHASE, RENTAL } = MediaComponentType;

export const useMediaComponentInfo = ({ type, size, template, item, onClick, enabled }: MediaComponentProps) => {
	const router = useRouter();

	const { t } = useTranslation();

	const isStrapi = item?.image;
	const isSeries = useMemo(() => item instanceof Series || item.content instanceof Series, [item]);
	const isEpisode = useMemo(() => item instanceof Episode || item.content instanceof Episode, [item]);

	const title = useMemo(() => {
		if (![OVERLAY, OVERLAY_ICON].includes(template)) return null;

		switch (type) {
			case AD:
			case CONTENT:
			case PRODUCT:
			case EVENT:
				return item.title;
			case CATCHUP:
			case HISTORY:
				return isEpisode && item.series?.title ? item.series.title : item.title;
			case RENTAL:
				return isEpisode && item.content.series?.title ? item.content.series.title : item.content.title;
			case PURCHASE:
				return item.movie.title;
			default:
				return null;
		}
	}, [isEpisode, item, template, type]);

	const subtitle = useMemo(() => {
		if (![OVERLAY, OVERLAY_ICON].includes(template)) return null;

		switch (type) {
			case AD:
				return item.subtitle;
			case CONTENT:
				if (isEpisode) {
					return t('MediaComponent.Subtitle.Episode', { episodeNumber: item.episodeNumber });
				}

				if (isSeries) {
					if (item.seasons.length > 1) {
						return t('MediaComponent.Subtitle.Seasons', { count: item.seasons.length });
					}

					return t('MediaComponent.Subtitle.Episodes', { count: item.episodeCount });
				}

				if (item.imdbRating) {
					return t('MediaComponent.Subtitle.IMDb', { imdbRating: item.imdbRating });
				}

				return null;
			case RENTAL:
				return t('MediaComponent.Subtitle.Watched', { watchedAt: moment(item.validFrom).fromNow() });
			case HISTORY:
				return t('MediaComponent.Subtitle.Watched', { watchedAt: moment(item.position?.timestamp).fromNow() });
			case CATCHUP:
				if (isSeries) {
					return t('MediaComponent.Subtitle.Episodes', { count: item.episodeCount });
				}
				const license = item.getFirstLicense();
				if (license) {
					return moment(license.validFrom).format('D. MMMM YYYY');
				}
				return null;
			case PURCHASE:
				return t('MediaComponent.Subtitle.Purchased', { purchasedAt: moment(item.purchasedAt).fromNow() });
			case PRODUCT:
				if (item.type === ProductType.PPV) {
					const startTime = moment(item.startTime);
					const langString = startTime.isSame(moment(), 'day') ? 'MediaComponent.Subtitle.TodayAt' : 'MediaComponent.Subtitle.DateAt';

					return (
						item.subtitle ||
						t(langString, {
							time: startTime.format('HH:mm'),
							date: startTime.format('D. MMM'),
						})
					);
				}

				return item.subtitle;
			case EVENT:
				const startTime = moment(item.startTime);
				const endTime = moment(item.endTime);

				if (moment().isBetween(startTime, endTime)) {
					return t('MediaComponent.Subtitle.Live');
				}

				const langString = startTime.isSame(moment(), 'day') ? 'MediaComponent.Subtitle.TodayAt' : 'MediaComponent.Subtitle.DateAt';

				return t(langString, {
					time: startTime.format('HH:mm'),
					date: startTime.format('D. MMM'),
				});
			default:
				return null;
		}
	}, [isEpisode, isSeries, item, t, template, type]);

	const progress = useMemo(() => {
		switch (type) {
			case CONTENT:
			case HISTORY:
				return item instanceof Playable ? item.getViewingProgress() : 0;
			case RENTAL:
				return item.content.getViewingProgress();
			default:
				break;
		}

		return 0;
	}, [item, type]);

	const backgroundImages = useMemo(() => {
		const presets = template === PROMO ? [ImagePreset.STILL_LARGE] : [`module-${size.toLowerCase()}`];

		switch (type) {
			case CONTENT:
			case CATCHUP:
			case HISTORY:
				if (isSeries && size === HOPSTER) {
					return [item.getHopsterImageUrl()];
				}
				if (isEpisode) {
					return [item.getStillImageUrl({ presets }), item.getBackdropImageUrl({ presets })];
				}
				if (isStrapi) {
					return [item.image.data.attributes.url];
				}
				return [template === PROMO ? item.getPromoImageUrl({ presets }) : null, item.getBackdropImageUrl({ presets })];
			case AD:
				return [item.getImageUrl({ presets })];
			case RENTAL:
				return [item.content.getBackdropImageUrl({ presets })];
			case PURCHASE:
				return [item.movie.getBackdropImageUrl({ presets })];
			case PRODUCT:
				return [item.getModuleImageUrl({ presets }), item.getBackdropImageUrl({ presets })];
			case EVENT:
				return [item.getBackdropImageUrl({ presets })];
			default:
				return null;
		}
	}, [template, size, type, isSeries, isEpisode, isStrapi, item]);

	const iconImage = useMemo(() => {
		if (template !== ModuleTemplate.OVERLAY_ICON) return null;

		try {
			let product = null;

			const presets = ['product-icon-tiny'];

			switch (type) {
				case CONTENT:
					if (isEpisode) {
						return playIconImage.src;
					}
					product = item.getProduct();
					break;
				case AD:
				case CATCHUP:
				case HISTORY:
					product = item.getProduct();
					break;
				case RENTAL:
					product = item.license.getProduct();
					break;
				case PURCHASE:
					// eslint-disable-next-line prefer-destructuring
					product = item.product;
					break;
				case PRODUCT:
					// eslint-disable-next-line prefer-destructuring
					product = item;
					break;
				case EVENT:
					return item?.channel?.getIconImageUrl({ presets });
				default:
					break;
			}

			if (product) {
				return product.getIconImageUrl({ presets });
			}
		} catch (exception) {}

		return null;
	}, [isEpisode, item, template, type]);

	const handleClick = useCallback(
		(e) => {
			e.preventDefault();

			if (enabled === false) return;

			if (onClick) {
				onClick(item);
				return;
			}

			switch (type) {
				case CONTENT:
					if (isSeries) {
						router.push(`/series/${item.id}`);
					} else if (isEpisode) {
						router.push(`/series/${item.series.id}/play`);
					} else {
						router.push(`/movie/${item.id}`);
					}
					break;
				case HISTORY:
				case CATCHUP:
					if (isSeries) {
						router.push(`/series/${item.id}`);
					} else if (isEpisode) {
						router.push(`/series/${item.series.id}`);
					} else {
						router.push(`/movie/${item.id}`);
					}
					break;
				case AD:
					const url = getAdUrl(item);
					if (url) {
						router.push(url);
					}
					break;
				case RENTAL:
					if (item.content instanceof Episode) {
						router.push(`/series/${item.content.series.id}`);
					} else {
						router.push(`/movie/${item.content.id}`);
					}
					break;
				case PURCHASE:
					router.push(`/movie/${item.movie.id}`);
					break;
				case PRODUCT:
					router.push(`/product/${item.id}`);
					break;
				case EVENT:
					// Disable click unless event is current with a 5 minute (300 second) buffer.
					if (item.isCurrent(300) && item?.channel?.id) {
						router.push(`/live/${item.channel.id}`);
					}
					break;
				default:
					break;
			}
		},
		[enabled, router, isEpisode, isSeries, item, onClick, type]
	);

	return { title, subtitle, progress, backgroundImages, iconImage, handleClick };
};
