export enum MediaComponentType {
	AD = 'ad',
	CATCHUP = 'catchup',
	CATEGORY = 'category',
	CONTENT = 'content',
	EVENT = 'event',
	HISTORY = 'history',
	PRODUCT = 'product',
	PURCHASE = 'purchase',
	RENTAL = 'rental',
}
